<template>
  <div>
    <!-- web carousel sec -->
    <div
      class="drawToggle"
      :style="{
        backgroundColor: `${themeData.primaryColor}`,
        padding: '20px 0px 20px',
      }"
      v-if="!winner?.is"
    >
      <div class="d-flex justify-center mb-4">
        <p class="drawInCountdown">Draw in</p>
      </div>
      <div
        v-if="
          getAllData?.Items?.status === 0 || getAllData?.Items?.status === 2
        "
        class="customLandingPageDaysLeftBg"
      >
        <div>
          <p class="customlandingTimeLeftTxt">DAYS</p>
          <p class="customlandingTimer">00</p>
        </div>
        <div>
          <p class="customlandingTimeLeftTxt">HRS</p>
          <p class="customlandingTimer">00</p>
        </div>
        <div>
          <p class="customlandingTimeLeftTxt">MINS</p>
          <p class="customlandingTimer">00</p>
        </div>
        <div>
          <p class="customlandingTimeLeftTxt">SECS</p>
          <p class="customlandingTimer">00</p>
        </div>
      </div>
      <div class="customLandingPageDaysLeftBg" v-else>
        <div>
          <p class="customlandingTimeLeftTxt">DAYS</p>
          <p class="customlandingTimer">{{ countdownTimers[0].days }}</p>
        </div>
        <div>
          <p class="customlandingTimeLeftTxt">HRS</p>
          <p class="customlandingTimer">{{ countdownTimers[0].hours }}</p>
        </div>
        <div>
          <p class="customlandingTimeLeftTxt">MINS</p>
          <p class="customlandingTimer">{{ countdownTimers[0].minutes }}</p>
        </div>
        <div>
          <p class="customlandingTimeLeftTxt">SECS</p>
          <p class="customlandingTimer">{{ countdownTimers[0].secs }}</p>
        </div>
      </div>
    </div>
    <div
      class="drawToggle"
      :style="{
        backgroundColor: `${themeData.primaryColor}`,
        padding: '20px 0px 20px',
        display: 'flex',
        alignItems: 'center',
      }"
      v-else
    >
      <h3>
        This raffle has now finished, your winner was "{{ winner?.name[0] }}"
      </h3>
    </div>
  </div>
</template>

<script>
import "@/assets/css/customRaffleLandingPage.css";
import "@/assets/css/global.css";
import "@/assets/css/toggle.css";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    countdownTimers: [
      {
        days: "00",
        hours: "00",
        minutes: "00",
        secs: "00",
      },
    ],
    countDownTime: "",
  }),
  mounted() {},
  created() {
    this.countDownTime = this?.raffleData?.drawing_in;
    console.log("Theme", this.themeData);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.$store.dispatch("fetch_custom_raffle").then(() => {
      console.log("ghdgfggfeg", this.getAllData);
    });
    this.startCountdown();
  },
  components: {},
  computed: {
    ...mapGetters(["getAllData"]),
  },
  props: {
    // countDownTime: {
    //   type: String,
    //   required: true,
    // },
    themeData: {
      type: Object,
      required: true,
    },
    raffleData: {
      type: Object,
      required: true,
    },
    winner: {
      type: Object,
      required: true,
    },
  },
  methods: {
    startCountdown() {
      const intervalId = setInterval(() => {
        this.countDownTime = this?.raffleData?.drawing_in;
        const inputDateStr = this.countDownTime;
        const parsedDate = new Date(
          inputDateStr.replace(/(\d+)(st|nd|rd|th)/, "$1")
        );
        const formattedDate = moment(parsedDate);
        const now = moment();

        console.log({
          localLocation_: moment.duration(formattedDate.diff(now)),
        });

        const duration = moment.duration(formattedDate.diff(now));

        if (duration.asMilliseconds() <= 0 || this?.raffleData?.status === 5) {
          clearInterval(intervalId);
          this.countdownTimers = [
            { days: "00", hours: "00", minutes: "00", secs: "00" },
          ];
          return;
        }

        const totalDays = duration.asDays();
        const days = String(parseInt(totalDays)).padStart(2, "0");
        const hours = String(duration.hours()).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");

        this.countdownTimers = [
          {
            days: days,
            hours: hours,
            minutes: minutes,
            secs: seconds,
          },
        ];
      }, 1000);
    },

    formatDate(dateString) {
      return moment(dateString).format("Do MMMM YYYY [at] HH:mm");
    },
  },
};
</script>