<template>
    <v-snackbar v-model="snackbarError" :timeout="timeout" :color="color">
        <div class="pl-2 pr-2 text-center"> {{ errorMessage }}</div>
    </v-snackbar>
    <div class="modal fade modalBackdropCustom p-0" id="newCartCheckout" data-bs-backdrop="static"
        data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog newCheckoutModalDialog">
            <div class="modal-content newCheckoutModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-regular fa-x newCheckoutCloseMark" data-bs-dismiss="modal" @click="close_dnaModal"
                            aria-label="Close"></i>
                    </div>
                    <h2 class="newCheckoutTxt">Payment</h2>
                    <div class="newExpressCheckoutSecBg">
                        <p class="expressCheckTxt mb-0">Express Checkout</p>

                        <div id="googlePayButtonModalContainer" class="applePayBtn"></div>

                        <div class="mt-2">
                            <div id="applePayButtonModalContainer" class="applePayBtn"></div>
                        </div>
                    </div>
                    <div class="newCheckoutPayWithHrLine">
                        <p class="newCheckoutPayWithTxt mb-0">Or pay with</p>
                    </div>

                    <!-- OR PAY WITH -->
                    <div class="newCheckoutPaymentOptionsSec">
                        <div class="newCheckoutCardsBg" @click="addActivePayCard()"
                            :class="{ 'active': selectedMethod == 'payWithCard' }">
                            <i class="fa-solid fa-credit-card creditCardIcon"
                                :class="{ 'active': selectedMethod == 'payWithCard' }"></i>
                            <p class="newCheckoutPayCardTxt mb-0"
                                :class="{ 'active': selectedMethod == 'payWithCard' }">
                                Pay with Card</p>
                        </div>
                        <div class="newCheckoutCardsBg" @click="addActivePaypal()"
                            :class="{ 'active': selectedMethod == 'payWithPaypal' }">
                            <div class="paypalImgBg">
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newPaypalImg.svg"
                                    alt="">
                            </div>
                        </div>
                    </div>

                    <!-- PAYPAL -->
                    <div v-if="selectedMethod == 'payWithPaypal'">
                        <p class="paypalRedirectTxt">You will be redirected to PayPal to complete the payment securely.
                        </p>
                        <div id="paypalButtonContainer" class="paypalBtnBg"></div>
                    </div>

                    <!-- <Card :dataData="dataData" @addActiveCard="addActiveCard"/> -->

                    <!-- CARD PAYMENT -->
                    <div v-if="selectedMethod == 'payWithCard'">
                        <form action="javascript:void(0)" class="container p-0">
                            <div id="loader">Loading token ....</div>
                            <div id="hosted-fields" class="newCheckoutPayWithCardSecBg" style="display: none">

                                <div>
                                    <!-- v-show="cardDetailsCheck === 1" -->
                                    <button v-if="cardDetailsCard.length > 0" type="button" class="btn dnaBackBtn"
                                        @click="call_cardDetails()">
                                        Back to card list
                                    </button>
                                    <div class="row mb-0">
                                        <div class="col-xs-12">
                                            <div class="input-group">
                                                <div id="hf-name" class="form-control checkoutCardHolderInputs"
                                                    placeholder="Cardholder name"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <div class="col-xs-12">
                                            <div class="input-group">
                                                <div id="hf-number" class="form-control checkoutCardHolderInputs"
                                                    placeholder="Card number"></div>
                                                <span class="card-icon visa"></span>
                                                <span class="card-icon mastercard"></span>
                                                <span class="card-icon amex"></span>
                                                <span class="card-icon dots"></span>
                                                <!-- <i class="fa-light fa-square-ellipsis card-icon dots"></i> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="checkoutCardExpirySec">
                                        <!-- <div class="input-group" :class="{ 'typing': isTyping }" @input="handleInput">
                                            <div id="hf-date" class="form-control checkoutCardExpirationField" ></div>
                                        </div> -->
                                        <div class="input-group" :class="{ 'typing': isTyping }">
                                            <div id="hf-date" class="form-control checkoutCardExpirationField"
                                                @input="handleInput" @focus="handleFocus"
                                                @blur="handleBlur">
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <div id="hf-cvv" class="form-control checkoutCvvTxt" placeholder="CVV">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- remember card-->
                                    <!-- <div class="form-check remeberCardBg" @click="remember_card()"
                                        :class="{ active: isActive }">
                                        <input class="form-check-input remeberCardRadio" type="radio"
                                            name="flexRadioDefault" id="flexRadioDefault1"
                                            :class="{ active: isActive }" />
                                        <label class="form-check-label rememberCardlabel" @click="remember_card()"
                                            for="flexRadioDefault1" :class="{ active: isActive }">
                                            Remember this card
                                        </label>
                                    </div> -->
                                </div>

                                <!-- rememberd card editable -->

                                <!-- <div id="cardDetailsDiv" class="rememberedCardsSec">
                                    <div class="row rememberedCardEditBg" v-for="(cardData, index) in cardDetailsCard"
                                        :key="index" @click="selectCardDetails(cardData, index)"
                                        :class="{ 'active-card': selectedCardIndex === index }">
                                        <div class="col-8 p-0">

                                            <h4 class="paymentCardHolderName">{{ cardData.card_name }}</h4>
                                            <div class="d-flex">
                                                <p class="rememberdCardNumber mb-0">
                                                    <img :src="cardData.cardImage" alt=""
                                                        class="PaymentMasterCardImg" />
                                                    Ending {{ cardData.pan_star.slice(-4) }}
                                                </p>
                                                <p class="mb-0 rememberedCardExpiry">Expires {{ cardData.expiry_date }}
                                                </p>
                                            </div>
                                            <h3 v-if="selectedCardIndex === index" class="enterCvvCodeTxt">Enter CVV
                                                Security code</h3>

                                            <p v-if="selectedCardIndex === index" class="cvvCodeDesc mb-0">
                                                Usually found on the back of the card
                                            </p>
                                        </div>
                                        <div class="col-4 p-0 text-end rememberdCardEditRightSec">

                                            <i class="fa-regular fa-xmark paymentCardEditIcon"
                                                @click="delete_card_details(cardData)"></i>
                                            <div class="col-xs-6 dnaCvvWidth" v-if="selectedCardIndex === index">
                                                <div class="input-group">
                                                    <span class="input-group-addon">
                                                        <span class="glyphicon glyphicon-lock"></span>
                                                    </span>
                                                    <div :id="selectedCardIndex === index ? 'hf-cvv-token' : ''"
                                                        class="form-control paymentCardEditCvvCode"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div v-if="cardDetailsCard.length > 0 && cardDetailsCheck != 1"
                                    class="newCheckoutAddCartHrLine" @click="go_to_cardDetails">
                                    <p class="newCheckoutAddCardTxt mb-0">Add card</p>
                                </div> -->

                                <!-------pay button-->
                                <div class="input-group pay-group">
                                    <div id="pay-btn" class="btn checkoutPayNowBtn" type="submit" value="Pay"
                                        data-loading-text="Loading..." @click="payBtn"
                                        :style="{ backgroundColor: themeData?.secondaryColor }"></div>
                                </div>

                            </div>

                            <div class="row">
                                <!-- <div id="success" class="alert alert-success" role="alert" style="display: none">
                                    Payment was successfull
                                </div> -->
                                <!-- <div id="failed" class="alert alert-danger" role="alert" style="display: none">
                                    Payment was unsuccessfull
                                </div> -->
                                <pre id="errors" class="alert alert-danger" role="alert" style="display: none"></pre>
                            </div>

                        </form>
                    </div>

                    <!-- Modal -->
                    <div class="modal fade" id="threeDSecureModal" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 class="modal-title" id="myModalLabel">
                                        Three D Secure
                                    </h4>
                                </div>
                                <div class="modal-body" id="threeDSecureModalContent"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

import { apiURL } from "@/api/api.js";
import Card from '../../payments/cards.vue';
import dataData from './data.js';

import "@/assets/css/global.css";

export default {
    data() {
        return dataData;
    },
    created() {
        this.storedCheckoutCart = JSON.parse(sessionStorage.getItem("PaymentData"));
        // this.addActivePayCard();
        if (!this.themeData || !this.themeData.secondaryColor) {
            // Fetch themeData if not available
            this.fetchThemeData(); // Assuming you have an action to fetch themeData
        } else {
            this.setupPayButton(); // Setup pay button if themeData is already available
        }
    },
    components: { Card },
    mounted() {

        this.setupAllObservers();

        $('#newCartCheckout')?.modal('show');
        this.fetchToken().then(result => {
            this.isLoading = false;
            this.paymentData = this.getPaymentData();
            this.ACCESS_TOKEN = result.access_token;

            $("#loader").hide();
            $("#hosted-fields").show();

            this.initializeHostedFields(result.access_token);
            this.loadGooglePayComponentScript(result.access_token);

            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                this.loadApplePayComponentScript();
                document.getElementById('applePayButtonModalContainer').style.display = 'block';
            } else {
                document.getElementById('applePayButtonModalContainer').style.display = 'none';
            }

            this.loadingPaypalScript(result.access_token);
        });

        const payBtn = document.querySelector("#pay-btn");
        if (payBtn) {
            const formattedAmount = this.storedCheckoutCart.wallet.amount.toFixed(2);
            payBtn.innerHTML = `Pay <span class="amount">£${formattedAmount}</span>`;
        }

    },
    computed: {
        ...mapGetters(["getThemeData"]),
        themeData() {
            return this.getThemeData;
        }
    },
    methods: {
        setupPayButton() {
            // Format amount and set button text
            if (this.storedCheckoutCart) {
                this.formattedAmount = this.storedCheckoutCart.wallet.amount.toFixed(2);
            }
        },
        fetchThemeData() {
            // Fetch themeData from Vuex or API
            this.$store.dispatch('fetch_custom_raffle').then(() => {
                this.isLoading = false;
                this.setupPayButton(); // Setup pay button after themeData is fetched
            }).catch((error) => {
                console.error('Error fetching themeData:', error);
                this.isLoading = false;
            });
        },
        // Initial funcs
        checkValidity(elementId, iconId) {
            const element = document.getElementById(elementId);
            if (element) {
                if (element.classList.contains('dna-payments-hosted-field-valid')) {
                    document.getElementById(iconId).style.display = "block";
                } else {
                    document.getElementById(iconId).style.display = "none";
                }
            }
        },
        checkNameValidity() {
            const hfNameElement = document.getElementById('hf-name');
            if (hfNameElement) {
                if (hfNameElement.classList.contains('dna-payments-hosted-field-valid')) {
                    document.getElementById('dnaCircleIcon').style.display = 'block';
                } else {
                    document.getElementById('dnaCircleIcon').style.display = 'none';
                }
            }
        },
        checkNumberValidity() {
            const hfNumberElement = document.getElementById('hf-number');
            if (hfNumberElement) {
                if (hfNumberElement.classList.contains('dna-payments-hosted-field-valid')) {
                    document.getElementById('dnaNumCircleIcon').style.display = 'block';
                } else {
                    document.getElementById('dnaNumCircleIcon').style.display = 'none';
                }
            }
        },
        checkDateValidity() {
            const hfDateElement = document.getElementById('hf-date');
            if (hfDateElement) {
                if (hfDateElement.classList.contains('dna-payments-hosted-field-valid')) {
                    document.getElementById('dnaExpiryCircleIcon').style.display = 'block';
                } else {
                    document.getElementById('dnaExpiryCircleIcon').style.display = 'none';
                }
            }
        },
        checkCvvValidity() {
            const hfCvvElement = document.getElementById('hf-cvv');
            if (hfCvvElement) {
                if (hfCvvElement.classList.contains('dna-payments-hosted-field-valid')) {
                    document.getElementById('dnaCvvCircleIcon').style.display = 'block';
                } else {
                    document.getElementById('dnaCvvCircleIcon').style.display = 'none';
                }
            }
        },
        addCheck() {
            this.checkNameValidity();
            this.checkNumberValidity();
            this.checkDateValidity();
            this.checkCvvValidity();
        },
        setupMutationObserver(elementId) {
            const element = document.getElementById(elementId);
            if (element) {
                const observer = new MutationObserver((mutationsList) => {
                    for (let mutation of mutationsList) {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                            console.log(`Class attribute changed for ${elementId}`);
                            // this.addCheck();

                        }
                    }
                });

                observer.observe(element, {
                    attributes: true
                });
            } else {
                console.log(`Element with id ${elementId} not found`);
            }
        },
        setupAllObservers() {
            const elements = ['hf-name', 'hf-number', 'hf-date', 'hf-cvv'];
            elements.forEach(id => this.setupMutationObserver(id));
        },
        // Loader functions
        startLoading() {
            $(document.querySelector("#pay-btn")).button("loading");
        },
        stopLoading() {
            document.querySelector("#pay-btn").disabled = false;
        },
        showResult(isSuccess, text) {
            // const successElement = document.querySelector("#success");
            // const failedElement = document.querySelector("#failed");

            if (isSuccess) {
                // failedElement.style.display = "none";
                // successElement.style.display = "block";
            } else {
                //successElement.style.display = "none";
                // failedElement.style.display = "block";
                // failedElement.innerHTML = text || "Payment was unsuccessful";
                // setTimeout(() => {
                //     failedElement.style.display = "none";
                // }, 3000);
            }
        },
        clear() {
            // const successElement = document.querySelector("#success");
            // const failedElement = document.querySelector("#failed");
            const errorsElement = document.querySelector("#errors");
            //successElement.style.display = "none";
            // failedElement.style.display = "none";
            errorsElement.textContent = "";
            errorsElement.style.display = "none";
        },
        // DNA TOKENS
        fetchToken() {
            let result = {
                access_token: this.storedCheckoutCart.wallet.paymentData.access_token
            };
            return Promise.resolve(result);
        },
        close_dnaModal() {
            sessionStorage.removeItem('PaymentData');
            // this.$router.push('/raffle');
            window.history.back();
            // window.location.reload();
        },
        onError(error) {
            const googlePayButton = document.getElementById('googlePayButtonModalContainer');
            googlePayButton.style.pointerEvents = 'auto';
            const applePayButton = document.getElementById('applePayButtonModalContainer');
            applePayButton.style.pointerEvents = 'auto';

            if (error.code == 1001) {
                this.snackbarError = true;
                this.errorMessage = error.message

            } else if (error.code == 1002) {
                this.snackbarError = true;
                this.errorMessage = error.message

            } else {
                // sessionStorage.removeItem('PaymentData');
                window.location.replace("/PaymentFailure")
            }

        },

        onPaymentSuccess(result) {
            const googlePayButton = document.getElementById('googlePayButtonModalContainer');
            googlePayButton.style.pointerEvents = 'auto';
            const applePayButton = document.getElementById('applePayButtonModalContainer');
            applePayButton.style.pointerEvents = 'auto';
            var data = this.storedCheckoutCart.wallet
            let paymentSucess = `/paymentSuccess?merchantOrderId=${data.invoiceId}&transactionId=${data.paymentData.terminalId}&checkoutValue=${data.amount}`;
            gtag('event', 'payment_success', {
                'event_category': 'engagement',
                'event_label': 'Payment Success',
                'value': 1
            });
            //sessionStorage.removeItem('PaymentData');
            window.location.replace(paymentSucess);
        },
        onCancel() {
            const googlePayButton = document.getElementById('googlePayButtonModalContainer');
            googlePayButton.style.pointerEvents = 'auto';
            const applePayButton = document.getElementById('applePayButtonModalContainer');
            applePayButton.style.pointerEvents = 'auto';
            sessionStorage.removeItem('PaymentData');
            // this.$router.push('/paymentPending');
            // this.$router.push('/raffle');
            window.location.replace("/PaymentFailure")
        },
        // Click process of gpay
        onClickGoogle() {
            const googlePayButton = document.getElementById('googlePayButtonModalContainer');
            googlePayButton.style.pointerEvents = 'none';
        },
        getPaymentData() {
            return {
                currency: "GBP",
                description: "Ticket purchase",
                paymentSettings: {
                    terminalId: this.storedCheckoutCart.wallet.paymentData.terminalId,
                    callbackUrl: apiURL.getPaymentSuccess,
                    failureCallbackUrl: apiURL.getPaymentFailure,
                },
                customerDetails: {
                    email: this.storedCheckoutCart.wallet.email,
                },
                deliveryType: "service",
                invoiceId: this.storedCheckoutCart.wallet.invoiceId,
                amount: this.storedCheckoutCart.wallet.amount,
            };
        },
        // Initilize the google pay button
        loadGooglePayComponentScript() {
            const script = document.createElement('script');
            if (process.env.VUE_APP_ENV === "prod" || process.env.VUE_APP_ENV === "preprod") {
                script.src = 'https://pay.dnapayments.com/components/google-pay/google-pay-component.js';
            } else {
                script.src = 'https://test-pay.dnapayments.com/components/google-pay/google-pay-component.js';
            }
            script.defer = true;
            script.onload = () => {
                // Add the CSS after the component has loaded
                const style = document.createElement('style');
                style.textContent = `.gpay-button-fill {
                                            position: absolute !important;
                                            top: 0 !important;
                                            width:90% !important;
                                            margin-top:22px;
                                        }`;
                document.head.appendChild(style);
                this.pay_google();
            };

            script.onerror = () => {
                console.error('Error loading Google Pay component script');
            };
            document.body.appendChild(script);
        },
        // On click of the google pay button actions
        async pay_google() {
            const paymentData = this.getPaymentData();

            const events = {
                onClick: this.onClickGoogle,
                onError: this.onError,
                onPaymentSuccess: this.onPaymentSuccess,
                onCancel: this.onCancel
            };

            const token = this.storedCheckoutCart.wallet.paymentData.access_token;

            const component = await window.DNAPayments.GooglePayComponent.create(
                document.getElementById('googlePayButtonModalContainer'),
                paymentData,
                events,
                token
            );

        },

        // Apple pay
        loadApplePayComponentScript() {
            // Clear existing Apple Pay button to ensure fresh initialization
            const applePayButtonContainer = document.getElementById('applePayButtonModalContainer');
            if (applePayButtonContainer) {
                applePayButtonContainer.innerHTML = ''; // Clear existing content
            }

            // Check if the script is already loaded
            let script = document.getElementById('applePayScript');
            if (script) {
                this.pay_apple_pay();
            } else {
                script = document.createElement('script');
                script.id = 'applePayScript';
                if (process.env.VUE_APP_ENV === "prod" || process.env.VUE_APP_ENV === "preprod") {
                    script.src = 'https://pay.dnapayments.com/components/apple-pay/apple-pay-component.js';
                } else {
                    script.src = 'https://test-pay.dnapayments.com/components/apple-pay/apple-pay-component.js';
                }
                script.defer = true;

                script.onload = () => {
                    // Add the CSS after the component has loaded
                    const style = document.createElement('style');
                    style.textContent = `
                  .apple-pay-button {
                      position: absolute !important;
                      top: 0 !important;
                      width: 90% !important;
                      margin-top: 22px;
                  }
                `;
                    document.head.appendChild(style);
                    this.pay_apple_pay();
                };

                script.onerror = () => {
                    console.error('Error loading Apple Pay component script');
                };

                document.body.appendChild(script);
            }
        },
        onClickApple() {
            const applePayButton = document.getElementById('applePayButtonModalContainer');
            applePayButton.style.pointerEvents = 'none';
        },
        pay_apple_pay() {
            const paymentData = this.paymentData

            const token = this.storedCheckoutCart.wallet.paymentData.access_token

            const events = {
                onClick: this.onClickApple,
                onError: this.onError,
                onPaymentSuccess: this.onPaymentSuccess,
                onCancel: this.onCancel
            };

            const component = window.DNAPayments.ApplePayComponent.create(
                document.getElementById('applePayButtonModalContainer'),
                paymentData,
                events,
                token
            );
        },


        // PayPal
        addActivePaypal() {
            this.selectedMethod = 'payWithPaypal'
            this.loadingPaypalScript()
        },
        loadingPaypalScript() {

            const script = document.createElement('script');
            if (process.env.VUE_APP_ENV === "prod" || process.env.VUE_APP_ENV === "preprod") {
                script.src = 'https://pay.dnapayments.com/checkout/payment-api.js';
            } else {
                script.src = 'https://test-pay.dnapayments.com/checkout/payment-api.js';
            }
            script.defer = true;
            // const script = document.createElement('script');
            // script.src = 'https://test-pay.dnapayments.com/checkout/payment-api.js';
            script.onload = () => {
                this.initializePayPalButton();
            };
            script.onerror = () => {
                console.error('Failed to load PayPal script');
            };
            document.head.appendChild(script);
        },
        initializePayPalButton() {
            if (!this.storedCheckoutCart) {
                console.error('Payment data is not available');
                return;
            }

            const payPalPaymentData = {
                currency: 'GBP',
                description: 'Payment description',
                paymentSettings: {
                    terminalId: this.storedCheckoutCart.wallet.paymentData.terminalId,
                    callbackUrl: apiURL.getPaymentSuccess,
                    failureCallbackUrl: apiURL.getPaymentFailure,
                },
                customerDetails: {
                    email: this.storedCheckoutCart.wallet.email,
                },
                auth: {
                    access_token: this.storedCheckoutCart.wallet.paymentData.access_token,
                    expires_in: 7200,
                    scope: 'payment integration_seamless',
                    token_type: 'Bearer'
                },
                invoiceId: this.storedCheckoutCart.wallet.invoiceId,
                amount: this.storedCheckoutCart.wallet.amount,
            };

            window.DNAPayments.configure({
                events: {
                    opened: () => {
                        console.log('The embedded widget has been opened');
                    },
                    cancelled: () => {
                        window.location.replace("/PaymentFailure");
                    },
                    paid: () => {
                        window.DNAPayments.closePaymentWidget()
                        var data = this.storedCheckoutCart.wallet
                        let paymentSucess = `/paymentSuccesswithoutLogin?merchantOrderId=${data.merchantOrderId}&transactionId=${data.paymentData.terminalId}&checkoutValue=${data.amount}`;
                        // sessionStorage.removeItem('PaymentData')
                        gtag('event', 'payment_success', {
                                'event_category': 'engagement',
                                'event_label': 'Payment Success',
                                'value': 1
                            });
                        window.location.replace(paymentSucess);
                        // this.$router.push('/paymentSuccesswithoutLogin').then(() => {
                        //     this.$nextTick(() => {
                        //         window.location.reload();
                        //     });
                        // });
                    },
                    declined: () => {
                        window.DNAPayments.closePaymentWidget()
                        window.location.replace("/PaymentFailure");
                    }
                }
            });

            const embeddedWidgetContainer = document.getElementById('paypalButtonContainer');
            if (!embeddedWidgetContainer) {
                console.error('PayPal button container not found');
                return;
            }

            const config = {
                paymentMethods: ['PayPal'],
                container: embeddedWidgetContainer,
            };

            if (window.DNAPayments && window.DNAPayments.openEmbeddedWidget) {
                window.DNAPayments.openEmbeddedWidget(payPalPaymentData, config);
            } else {
                console.error('DNAPayments is not defined or openEmbeddedWidget method is missing');
            }
        },


        // CARD
        // Initilize the card fields
        initializeHostedFields(access_token) {
            const cardholderName = document.querySelector("#hf-name");
            const cardNumber = document.querySelector("#hf-number");
            const cardDate = document.querySelector("#hf-date");
            const cardCvv = document.querySelector("#hf-cvv");
            const threeDSecureModal = document.querySelector("#threeDSecureModal");
            const threeDSecureModalContent = document.querySelector(
                "#threeDSecureModalContent"
            );
            const $threeDSecureModal = $(threeDSecureModal);

            const payBtn = document.querySelector("#pay-btn");
            if (payBtn) {
                const formattedAmount = this.storedCheckoutCart.wallet.amount.toFixed(2);

                payBtn.innerHTML = `Pay <span class="amount">£${formattedAmount}</span>`;
            }
            const theme = true; // JSON.parse(localStorage.getItem('isChecked'))
            const darkTextColor = "#ffffff";
            const lightTextColor = "#000000";

            const textColor = theme == true ? lightTextColor : darkTextColor;

            const darkBorderColor = "#ffffffb3 !important";
            const lightborderColor = "#00061633 !important";

            const borderColor = theme == true ? lightborderColor : darkBorderColor;

            let isTest = !(process.env.VUE_APP_ENV === "prod" || process.env.VUE_APP_ENV === "preprod") ? true : false


            window.dnaPayments.hostedFields
                .create({
                    isTest: isTest,
                    accessToken: access_token,
                    styles: {
                        input: {
                            "font-size": "14px",
                            "font-family": "Open Sans",
                            // "color": "black"
                        },
                        ".valid": {
                            "color": "black"
                        },
                        ".invalid": {
                            color: "red"
                        }
                    },
                    threeDSecure: {
                        container: threeDSecureModalContent
                    },
                    sendCallbackEveryFailedAttempt: this.sendCallbackEveryFailedAttempt,
                    fontNames: ["Open Sans"],
                    fields: {
                        cardholderName: {
                            container: cardholderName,
                            placeholder: "Cardholder name"
                        },
                        cardNumber: {
                            container: cardNumber,
                            placeholder: "Card number"
                        },
                        expirationDate: {
                            container: cardDate,
                            placeholder: "Expiry date"
                        },
                        cvv: {
                            container: cardCvv,
                            placeholder: "CVV"
                        }
                    }
                })
                .then(res => {
                    this.hf = res;

                    this.hf.on("dna-payments-three-d-secure-show", data => {
                        $threeDSecureModal.modal("show");
                    });

                    this.hf.on("dna-payments-three-d-secure-hide", () => {
                        $threeDSecureModal.modal("hide");
                    });
                })
                .catch(e => {
                    this.errors = e;
                });
        },
        // Initilize if card is already added
        init(cardDetailsCard, hf) {
            this.cardDetailsCard = cardDetailsCard
            this.cardDetailsCard.forEach(item => {
                const cardDetails = {
                    merchantTokenId: item.merchant_token_id,
                    panStar: item.pan_star,
                    cardSchemeId: item.card_scheme_id.toString(),
                    cardSchemeName: item.card_scheme_name,
                    cardName: item.card_name,
                    expiryDate: item.expiry_date,
                    isCSCRequired: item.is_csc_required,
                    cscState: item.csc_state,
                };
                item.cardImage = this.hf.getCreditCardTypeIcon(cardDetails);
            });

            if (this.selectedCardIndex == null) {
                this.selectCardDetails(this.cardDetailsCard[0], 0)
            }
            // this.cardDetailsCheck = 2;
            const card = {
                merchantTokenId: this.selectedCard.merchant_token_id,
                panStar: this.selectedCard.pan_star,
                cardSchemeId: this.selectedCard.card_scheme_id.toString(),
                cardSchemeName: this.selectedCard.card_scheme_name,
                cardName: this.selectedCard.card_name,
                expiryDate: this.selectedCard.expiry_date,
                isCSCRequired: this.selectedCard.is_csc_required,
                cscState: this.selectedCard.csc_state
            }
            hf.selectCard(card);
        },
        // Selected cards
        selectCardDetails(cardData, index) {
            this.selectedCard = cardData;
            this.selectedCardIndex = index;
            this.initializePayment();
        },
        initializePayment() {
            this.fetchToken().then(result => {
                this.isLoading = false;
                this.paymentData = this.getPaymentData();
                this.initializeHostedFields(result.access_token);
                $("#loader").hide();
                $("#hosted-fields").show();
            }).catch(error => console.error(error));
        },

        addActivePayCard() {
            if (this.cardDetailsCard.length > 0) {

                this.selectedMethod = 'payWithCard'
                const cardDetailsDiv = document.getElementById('cardDetailsDiv');
                if (cardDetailsDiv) {
                    cardDetailsDiv.style.display = 'block';
                }
                this.isTokenizedCard = true;
                this.cardDetailsCheck = 0;
                this.initializePayment();
            } else {
                this.selectedMethod = 'payWithCard'
                this.cardDetailsCheck = 1
                this.initializePayment();
            }

        },

        // addActive() {

        //     this.selectedMethod = 'payWithCard'
        //     this.initializePayment();
        // },

        payBtn() {
            const payBtn = document.querySelector("#pay-btn");
            payBtn.innerHTML = "Loading...";
            this.startLoading();
            this.clear();
            this.hf.submit({
                paymentData: this.paymentData
            }).then(res => {
                this.stopLoading();
                this.hf.clear();
                this.showResult(true);
                if (res.data.success == true) {
                    if (this.isActive == true) {
                        this.add_card_details(res)
                    }

                    // sessionStorage.removeItem('PaymentData')
                    var data = this.storedCheckoutCart.wallet
                    let paymentSucess = `/paymentSuccess?merchantOrderId=${data.invoiceId}&transactionId=${data.paymentData.terminalId}&checkoutValue=${data.amount}`;
                        gtag('event', 'payment_success', {
                            'event_category': 'engagement',
                            'event_label': 'Payment Success',
                            'value': 1
                        });
                    window.location.replace( paymentSucess);

                    // this.$router.push('/paymentSuccess').then(() => {
                    //     this.$nextTick(() => {
                    //         window.location.reload();
                    //     });
                    // });

                } else {
                    window.location.replace("/PaymentFailure");
                }

            })
                .catch(err => {
                    this.stopLoading();
                    if (err.code === "NOT_VALID_CARD_DATA") {
                        this.showResult(false, err.message);
                    } else {
                        this.attemptCount++;
                        this.showResult(false, err.message);
                        if (
                            this.sendCallbackEveryFailedAttempt &&
                            this.attemptCount >= this.sendCallbackEveryFailedAttempt
                        ) {
                            this.attemptCount = 0;
                        }
                        this.hf.clear();
                    }

                })
                .finally(() => {

                    const payBtn = document.querySelector("#pay-btn");
                    const formattedAmount = this.storedCheckoutCart.wallet.amount.toFixed(2);
                    payBtn.innerHTML = `Pay <span class="amount">£${formattedAmount}</span>`;
                });

        },
    }

}
</script>

<style>
.modalBackdropCustom {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.form-check-input.remeberCardRadio:checked {
    background-color: transparent !important;
    border-color: #00061633 !important;
}

.form-check-input.remeberCardRadio:checked {
    --bs-form-check-bg-image: none !important;
}

.amount {
    margin-left: 10px;
    /* Adjust this value as needed for the desired spacing */
}

.row {
    margin-bottom: 1em;
}

/* .dna-payments-hosted-field-focused {
                    /* border-color: #80bdff; */
/* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
/* } */

.dna-payments-hosted-field-valid {
    border: 1.1px solid #00061633 !important;
    background: #0006161a !important;
}

.dark .dna-payments-hosted-field-valid {
    /* border-color: #28a745 !important; */
    border-color: rgba(255, 255, 255, 0.20) !important;
    /* opacity:0.2; */
    background: rgba(255, 255, 255, 0.10) !important;
}

.dna-payments-hosted-field-valid::after {
    content: "\f058";
    /* content: ""; */
    margin-right: -2px;
    font-family: "FontAwesome";
    font-size: 12px;
    height: 5%;
    color: #ffbd0a;
    background-image: none !important;
    top: 15px;
    right: 15px;
    bottom: 0;
    position: absolute;
}


.dna-payments-hosted-field-invalid {
    border-color: #dc3545 !important;
}

.dna-payments-hosted-field-invalid.dna-payments-hosted-field-focused {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

/* checkout modal */
.newCheckoutModalDialog {
    height: auto;
    border-radius: 12px;
}

.dark .newCheckoutModalDialog {
    background: #141729 !important;
}

.newCheckoutModalBg {
    min-height: auto;
    border-radius: 12px;
    background: #fff;
    padding: 16px 16px 24px 16px;
}

.dark .newCheckoutModalBg {
    background: #141729 !important;
}

.newCheckoutCloseMark {
    color: #000616e6;
    font-size: 24px;
    cursor: pointer;
    height: 17px !important;
}

.dark .newCheckoutCloseMark {
    color: #ffffffe6;
}

.newCheckoutTxt {
    color: #000616;
    text-align: center;
    font-family: "Gilroy ExtraBold";
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: -12px;
    margin-bottom: 27px;
}

.dark .newCheckoutTxt {
    color: #ffffff;
}

.newExpressCheckoutSecBg {
    width: 333px;
    border: 1px solid #00061626;
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    padding: 22px 15px 18px;
    margin-bottom: 25px;
}

.dark .newExpressCheckoutSecBg {
    border: 1px solid #97979726;
}

.applePayBtn {
    height: 40px;
}

#googlePayButtonModalContainer .gpay-button-fill button {
    height: 40px !important;
}

#googlePayButtonModalContainer iframe {
    height: 40px !important;
}

.expressCheckTxt {
    color: #000616cc;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: absolute;
    top: -10px;
    /* bottom: 0; */
    left: 78px;
    right: 0;
    background: #fff;
    width: 180px;
}

.dark .expressCheckTxt {
    background: #141729;
    color: #ffffffcc;
}

.checkoutGpayBg {
    width: 301px;
    height: 40px;
    border-radius: 4px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.iframeGoogleBtn {
    width: 301px;
    height: 40px;
    background: #000;
}

.checkoutAppleBg {
    width: 301px;
    height: 40px;
    border-radius: 4px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.newCheckoutPayWithHrLine {
    width: 333px;
    height: 0.25px;
    background: #00061634;
    margin: 0 auto;
    position: relative;
}

.dark .newCheckoutPayWithHrLine {
    background: #ffffff80;
}

.newCheckoutPayWithTxt {
    color: #000616cc;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 133px;
    height: 19px;
    background: #fff;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 100px;
    right: 0;
}

.dark .newCheckoutPayWithTxt {
    color: #ffffffcc;
    background: #141729;
}

.newCheckoutAddCardTxt {
    color: #000616cc;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 133px;
    height: 19px;
    background: #fff;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 100px;
    right: 0;
    cursor: pointer;
}

.dark .newCheckoutAddCardTxt {
    color: #ffffffcc;
    background: #141729;
}

.newCheckoutAddCartHrLine {
    width: 333px;
    height: 0.25px;
    background: #00061634;
    margin: 25px auto 0px;
    position: relative;
}

.dark .newCheckoutAddCartHrLine {
    background: #ffffff80;
}

.newCheckoutPaymentOptionsSec {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 26px;
}

.newCheckoutCardsBg {
    width: 112px;
    height: 64px;
    border-radius: 8px;
    border: 1.1px solid #00061633;
    /* opacity: 0.2; */
    background: #0006161a;
    padding: 11px 16px 10px 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.dark .newCheckoutCardsBg {
    border: 1.1px solid #ffffffcc;
    background: #ffffff1a;
}

.newCheckoutCardsBg.active {
    border-radius: 8px;
    border: 0.75px solid #ffbd0ae6;
    background: #ffbd0a1a;
}

.creditCardIcon {
    color: #00061666;
    font-size: 23px;
    height: 20px !important;
    text-align: center;
}

.dark .creditCardIcon {
    color: #ffffff66;
}

.creditCardIcon.active {
    color: #ffbd0a;
}

.newCheckoutPayCardTxt {
    color: #00061666;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 2px;
}

.dark .newCheckoutPayCardTxt {
    color: #ffffff66;
}

.newCheckoutPayCardTxt.active {
    color: #ffbd0a;
}

.checkoutpaypalImg {
    background-color: #fff;
    border-radius: 8px;
}

.newCheckoutPaypaltxt {
    color: #000616cc;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 24px;
}

.newCheckoutPaypalSec {
    margin: 40px 0px 0px;
}

.newCheckoutPaypalPayBtn {
    width: 301px;
    height: 40px;
    border-radius: 4px;
    background: #fdc239 !important;
    border-color: transparent !important;
}

.newCheckoutPayWithCardSecBg {
    width: 326px !important;
    margin: 27px auto 0px;
}

.dnaBackBtn {
    background: #00c08d !important;
    border-color: #00c08d !important;
    width: 150px;
    height: 32px;
    color: #fff !important;
    padding: 0px;
    margin-bottom: 16px;
    font-family: "Nunito Sans";
    font-size: 16px;
}

.dark .dnaBackBtn {
    color: #fff;
}

.checkoutCardHolderInputs {
    width: 326px !important;
    height: 44px;
    border-radius: 4px !important;
    border: 1.1px solid rgba(0, 6, 22, 0.2);
    background: #00061603 !important;
    color: #000616b3 !important;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 auto 20px;
    position: relative;
}

.dark .checkoutCardHolderInputs {
    border: 1.1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.01) !important;
}

.checkoutCardHolderInputs:focus {
    box-shadow: none !important;
}

.checkoutCardExpirySec {
    display: flex;
    justify-content: start;
    gap: 24px;
}

.checkoutCardExpirationField {
    width: 184px !important;
    height: 44px;
    border-radius: 4px !important;
    border: 1.1px solid rgba(0, 6, 22, 0.2);
    background: #00061603 !important;
    color: #000616b3 !important;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.checkoutCardExpirationField:focus {
    box-shadow: none !important;
}

.dark .checkoutCardExpirationField {
    border: 1.1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.01) !important;
}

.checkoutCvvTxt {
    width: 118px !important;
    height: 44px;
    border-radius: 4px !important;
    border: 1.1px solid rgba(0, 6, 22, 0.2);
    background: #00061603 !important;
    color: #000616b3 !important;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dark .checkoutCvvTxt {
    border: 1.1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.01) !important;
}

.checkoutCvvTxt:focus {
    box-shadow: none !important;
}

.remeberCardBg {
    display: flex;
    align-items: center;
    width: 326px !important;
    height: 40px;
    border-radius: 8px !important;
    border: 1.1px solid #00061633;
    background: #00061603 !important;
    padding: 10px 16px 11px 16px;
    margin: 24px 0px 22px;
    cursor: pointer;
}

.remeberCardBg.active {
    border-color: #ffbd0a;
}

.dark .remeberCardBg {
    border: 0.5px solid #ffffff4d;
}

.dark .remeberCardBg.active {
    border-color: #ffbd0a;
}

.remeberCardRadio {
    width: 20px;
    height: 20px;
    border-color: #00061633;
    cursor: pointer;
    margin-right: 0 !important;
    margin-top: 0 !important;
}

.remeberCardRadio.active {
    background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3e%3ccircle cx="12" cy="12" r="13" fill="%23ffbd0a" /%3e%3ccircle cx="12" cy="12" r="10" fill="%23ffbd0a" /%3e%3cpath d="M7 13l3.5 3.5L17 10" stroke="%23fff" stroke-width="3" fill="none" /%3e%3c/svg%3e');
    background-color: #ffbd0a;
    border-color: #ffbd0a !important;
}

.dark .remeberCardRadio {
    background-color: #141729 !important;
    border-color: #ffffff33 !important;
    --bs-form-check-bg-image: none !important;
}

.remeberCardRadio:focus {
    box-shadow: none !important;
}

.remeberCardRadio:checked.active {
    background-color: #ffbd0a;
    border-color: #ffbd0a !important;
    background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3e%3ccircle cx="12" cy="12" r="13" fill="%23ffbd0a" /%3e%3ccircle cx="12" cy="12" r="10" fill="%23ffbd0a" /%3e%3cpath d="M7 13l3.5 3.5L17 10" stroke="%23fff" stroke-width="3" fill="none" /%3e%3c/svg%3e');
}

/* .dark .remeberCardRadio:checked {
                        --bs-form-check-bg-image: url(data:image/svg + xml,
                     %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="-4 -4 8 8"%3e%3ccircler="2"fill="%23000616"/%3e%3c/svg%3e);
                        background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3e%3ccircle cx="12" cy="12" r="13" fill="%23ffbd0a" /%3e%3ccircle cx="12" cy="13" r="10" fill="%23ffbd0a" /%3e%3cpath d="M7 13l3.5 3.5L17 10" stroke="%23000616" stroke-width="3" fill="none" /%3e%3c/svg%3e');
                    } */

.rememberCardlabel {
    color: #000616b3 !important;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 13px;
    cursor: pointer;
}

.dark .rememberCardlabel {
    color: #ffffff99 !important;
}

.checkoutPayNowBtn {
    width: 326px;
    height: 56px;
    border-radius: 8px;
    background: #00c08d;
    border-color: transparent !important;
    color: #fff !important;
    font-family: "Gilroy ExtraBold";
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 0px 19px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.input-group {
    position: relative;
}

.card-icon {
    position: absolute;
    top: 35%;
    right: 10px;
    /* Adjust as needed */
    transform: translateY(-50%);
    width: 30px;
    /* Adjust as needed */
    height: 20px;
    /* Adjust as needed */
    background-size: contain;
    background-repeat: no-repeat;
}

.card-icon.visa {
    background-image: url("https://static.raffolux.com/static/website/redev_images/Visa.svg");
    right: 107px;
    width: 26px;
    height: 18px;
}

.card-icon.mastercard {
    background-image: url("https://static.raffolux.com/static/website/redev_images/Mastercard.svg");
    right: 73px;
    width: 26px;
    height: 18px;
}

.card-icon.amex {
    background-image: url("https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/americanExpressLogo.svg");
    right: 38px;
    width: 26px;
    height: 18px;
}

.card-icon.dots {
    right: 12px;
    content: "\e26e";
    font-size: 18px;
    font-family: "Font Awesome";
    color: #000616;
}

/* rememberd card css */
.rememberdCardBg {
    width: 326px;
    height: 74px;
    border-radius: 8px;
    background: #f5f5f5;
    margin: 0 auto;
    padding: 12px 16px 16px;
    cursor: pointer;
}

.dark .rememberdCardBg {
    opacity: 0.9;
    background: rgba(0, 0, 0, 0.25);
}

.remeberCardHolderName {
    color: rgb(0, 6, 22, 0.8);
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
}

.rememberdCardNumber {
    border-radius: 4px;
    background: #0006161a;
    width: 96px;
    height: 19px;
    flex-shrink: 0;
    color: #000616cc;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark .rememberdCardNumber {
    background: #ffffff1f;
    color: #ffffffcc;
}

.paypalImgBg {
    width: 58px;
    height: 40px;
    background: #fff;
    border-radius: 8px;
}

.PaymentMasterCardImg {
    width: 18px;
    height: 13px;
    margin-right: 7.7px;
}

.rememberedCardExpiry {
    width: 80px;
    height: 19px;
    border-radius: 4px;
    background: #0006161a;
    color: #000616cc;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
}

.dark .rememberedCardExpiry {
    background: #ffffff1f;
    color: #ffffffcc;
}

.active-card {
    border: 0.75px solid #ffbd0a;
}

.rememberedCardEditBg {
    width: 326px;
    height: 120px;
    border-radius: 8px;
    /* border: 0.75px solid #ffbd0a;  */
    background: #f5f5f5;
    margin: 24px auto 16px;
    padding: 12px 16px 15px;
    cursor: pointer;
}

.dark .rememberedCardEditBg {
    opacity: 0.9;
    background: rgba(0, 0, 0, 0.25);
}

.dnaDivHide {
    display: none;
}

.paymentCardHolderName {
    color: #000616;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
}

.dark .paymentCardHolderName {
    color: #fff;
}

.enterCvvCodeTxt {
    color: #000616;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 12px 0px 2px;
}

.dark .enterCvvCodeTxt {
    color: #fff;
}

.cvvCodeDesc {
    color: #00061699;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dark .cvvCodeDesc {
    color: #ffffff99;
}

.paymentCardEditIcon {
    font-size: 16px;
    color: #000616cc;
    cursor: pointer;
    width: 16px;
}

.dark .paymentCardEditIcon {
    color: #ffffffcc;
}

.paymentCardEditCvvCode {
    width: 100px;
    height: 32px;
    border-radius: 4px !important;
    border: 0.5px solid #00061680 !important;
    background: #0006161a !important;
}

.dark .paymentCardEditCvvCode {
    border: 0.5px solid #ffffffcc !important;
    background: rgba(255, 255, 255, 0.15) !important;
    color: #fff;
}

.dnaCvvWidth {
    width: 100px;
}

.dark .paymentCardEditCvvCode::placeholder {
    color: #ffffffcc;
}

.paymentCardEditCvvCode:focus {
    box-shadow: none !important;
}

.rememberdCardEditRightSec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.payBtnAmount {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.temp-gpay-button-fill {
    width: 100%;
    height: inherit;

}

.gpay-button-fill-test {
    position: absolute !important;
    top: 0 !important;
}

.temp-gpay-button-fill>.temp-gpay-button.black {
    padding: 12px 15% 10px;
    height: 40px !important;
}

.temp-gpay-button-fill>.temp-gpay-button.black {
    width: 100%;
    height: inherit;
}

.temp-gpay-button.black.short,
.temp-gpay-button.black.plain {
    background-image: url('https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcGpayImg.svg');
}

.temp-gpay-button.black {
    background-color: #000;
    box-shadow: none;
}

.temp-gpay-button {
    background-origin: content-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0px;
    border-radius: 4px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 1px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
    cursor: pointer;
    height: 40px;
    min-height: 40px;
    padding: 12px 24px 10px;
    width: 240px;
}

button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    font-style: none;
    font-variant-ligatures: none;
    font-variant-caps: none;
    font-variant-numeric: none;
    font-variant-east-asian: none;
    font-weight: 0;
    font-stretch: 0;
    font-size: 0;
    font-family: none;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    margin: 0em;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}

.paypalRedirectTxt {
    color: #000616cc;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 40px auto 24px;
    width: 310px;

}

.dark .paypalRedirectTxt {
    color: #ffffffcc;
}

._226N39lu-UKVnDydU-FhDJ {
    display: none !important;
}

.paypalBtnBg {
    margin-bottom: 146px;
}

.gpay-card-info-container.black {
    outline: 1px solid #000 !important;
}

.dnaTheme {
    display: none;
}

.typing {
    border: 2px solid #ffbd0a !important;
    /* Add other styles for typing state as needed */
}
</style>