<template>
  <div>
    <div
      class="row customRaffleNavBg m-0 position-relative"
      :style="{ '--font-color': themeData.primaryColor }"
    >
      <div class="col-4 pe-0 d-flex align-items-center h-100">
        <img
          v-if="
            this.templateSample?.header?.powered_by_logo?.is &&
            this.isPowerLogoRequired
          "
          :src="fetchImage(headerData.poweredByLogoImgSrc)"
          style="height: auto"
          class="customNavRaffoluxImg imageFitStyle support-logo-class"
          alt=""
        />
      </div>

      <div class="col-4">
        <div class="d-flex justify-content-center">
          <img
            v-if="this.templateSample?.header?.logo?.is"
            :src="fetchImage(headerData.logoImgSrc)"
            class="greatChristmasRaffleImg imageFitStyle"
            alt=""
            :style="{
              maxWidth: '200px',
              height: 'auto',
              borderRadius: '8px',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bucketUrlForImages } from "@/api/api.js";
import "@/assets/css/customRaffleLandingPage.css";
import "@/assets/css/global.css";
import "@/assets/css/toggle.css";

export default {
  data: () => ({}),
  mounted() {},
  created() {},
  components: {},
  props: {
    contentID: {
      type: String,
      required: true,
    },
    poweredBy: {
      type: String,
      required: true,
    },
    headerLogoSrc: {
      type: String,
      required: true,
    },
    headerData: {
      type: Object,
      required: true,
    },
    themeData: {
      type: Object,
      required: true,
    },
    templateSample: {
      type: Object,
      required: true,
    },
    isPowerLogoRequired: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  methods: {
    fetchImage(filenames) {
      return `${bucketUrlForImages}/${this.contentID}/${filenames}`;
    },
  },
};
</script>

<style scoped>
.customRaffleNavBg {
  background-color: var(--font-color) !important;
  z-index: 1;
}

.support-logo-class {
  max-width: 60px;
}

@media only screen and (max-width: 767px) {
  .support-logo-class {
    max-width: 50px !important;
  }
}
</style>
